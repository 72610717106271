body, body #root, h2, h3, h4, h5 {
    font-family: Arial,sans-serif;
}

iframe {
    border: 0;
}

figcaption,
figure,
main {
    display: block;
}

main {
    overflow: hidden;
}
