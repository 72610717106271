.css-select-height-button {
    /* height: calc(1em + 1rem + 2px); */
    height: auto;
    padding: 0.3rem 0.75rem;
    /* color: #8898aa; */
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
}

.color-option-selectTree {
    color: black;
}