.chart-tab-item {
    padding: 5px 10px;
    cursor: pointer;
    font-weight: bold;
}

.box-list .active {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 5px solid #1ec6e8 !important;
}

.chart-item-info {
    padding: 15px;
    background: #fff;
    border: 1px solid #1ec6e8
}

.config-system-chart {
    padding: 10px;
}

.chart-stamp {
    text-align: -webkit-right;
    width: 100%;
}

.chart-stamp input {
    width: 60px;
}