.wrapper {
    height: calc(100vh - 100px);
    padding: 20px 0;
    overflow: hidden;
    overflow-y: auto;
}

.error {
    color: red;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 0;
}

.editArea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.editArea .item {
    margin-right: 5px;
    cursor: pointer;
}

.editArea .item:last-child {
    margin-right: 0;
}

.noActiveStt {
    display: block;
    width: 7.5rem;
    margin: auto;
    color: #000;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.activeStt {
    display: block;
    width: 7.5rem;
    margin: auto;
    color: #04BF16;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.notyetStt {
    display: block;
    width: 7.5rem;
    margin: auto;
    color: orange;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.noActiveStt {
    background-color: #fff;
    border: 1px solid #000;
}

.notyetStt {
    background-color: #fff;
    border: 1px solid orange;
}

.activeStt {
    background-color: #fff;
    border: 1px solid #04BF16;
}

.editHeaderArea {
    width: 78px;
}

.searchArea {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.searchArea .searchRow {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.searchArea .searchRow:last-child {
    margin-bottom: 0;
}

.searchArea .searchRow .checkBoxItem {
    margin-right: 15px;
}

.searchArea .searchRow .checkBoxItem input[type=checkbox] {
    margin-right: 10px;
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.searchArea .searchRow .searchRowItem {
    width: 100%;
}

.formControl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.formControl .rowItem {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    justify-content: center;
    align-items: flex-start;
}

.formControl .rowItem .inputArea {
    width: 100%;
}

.formControl .rowItem label {
    display: flex;
    width: 160px;
    height: 43px;
    margin-bottom: 0;
    align-items: center;
}

.formControl .rowTitle {
    width: 100%;
}

.filterArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.filterArea label {
    width: 90px;
    margin-bottom: 0;
}

.filterArea select {
    width: 300px;
    margin-right: 8px;
    height: 2.2em;
    padding-top: 0;
    padding-bottom: 0;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.noActiveSta {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: #000;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.activeSta {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: #04BF16;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.notYesStt {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: orange;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.noActiveSta {
    background-color: #fff;
    border: 1px solid #000;
}

.notYesStt {
    background-color: #fff;
    border: 1px solid orange;

}

.activeSta {
    background-color: #fff;
    border: 1px solid #04BF16;
}

@media screen and (max-width: 991px) {
    .filterArea select {
        width: 200px;
    }
}

@media screen and (max-width: 568px) {
    .filterArea {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
        ;
    }

    .filterArea select {
        width: 100%;
    }
}

@media screen and (max-width: 359px) {
    .searchArea .searchRow .checkBoxItem input[type=checkbox] {
        margin-right: 8px;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }

    .searchArea .searchRow .checkBoxItem label {
        font-size: .8rem;
    }
}