.table-css-stampProvide .thead-dark th:nth-child(2) {
    width: 500px;
}

.table-css-stampProvide .thead-dark th:nth-child(4) {
    width: 300px;
}

.table-css-stampProvide .thead-dark th:nth-child(5) {
    width: 300px;
}

/* .table-css-stampProvide .thead-dark th:nth-child(6) {
    width: 200px;
} */

.table-css-stampProvide .thead-dark th:nth-child(7) {
    width: 200px;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.modal-css-footer-unconfirm {
    background-color: #d5d7dd;
    /* color: #fff !important; */
}

.modal-css-footer-unconfirm:hover {
    background-color: #d5d7dd;
}

.ml-button-stamp-provide {
    margin-left: 8px;
}

.rdtPicker table thead th.dow {
    position: inherit;
}

.css-font-htx-dn {
    font-weight: bold;
}

.css-label-stamp-provide {
    font-weight: bold;
    margin: 0px;
    min-width: 210px
}

.css-p-stamp-provide {
    padding: 0;
    margin: 0px;
    text-align: center;
}

.modal-body-item-textarea {
    margin-top: 0px;
}

.tamp-provide-use-css-check input[type="checkbox"] {
    /* outline: 1px solid #fff !important; */
    font-size: 18px;
    visibility: hidden;
    /* -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -ms-appearance: textfield;
    -o-appearance: textfield; */

}

.tamp-provide-use-css-check input[type="checkbox"][checked] {
    /* outline: 1px solid #fff !important; */
    font-size: 16px;
    visibility: hidden;
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;*/
    appearance: none;
}

.tamp-provide-use-css-check input[type="checkbox"][checked]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */


}

.tamp-provide-use-css-check input[type=checkbox] {
    content: " ";
    /* background-color: #fff; */
    display: inline-block;
    color: #04BF16;
    -moz-border-colors: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    /* border: 1px solid #04BF16; */
    /* padding: 0 3px; */
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: none; */
    -moz-appearance: none;
    /* -ms-appearance: none;
    -o-appearance: none; */
    appearance: none;


}

.tamp-provide-use-css-check input[type=checkbox][disabled] {
    content: " ";
    /* background-color: #fff; */
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    /* border: 1px solid #04BF16; */
    /* padding: 0 3px; */
    /* margin: 5px; */

    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */

}


.tamp-provide-use-css-check input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */

}

.tamp-provide-use-css-check input[type=checkbox]:not(:checked):after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    /* margin: 5px; */
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    /* -webkit-appearance: checkbox;
    -moz-appearance: none;
    -ms-appearance: checkbox;
    -o-appearance: checkbox;
    appearance: none; */

}

.tamp-provide-use-css-check input[type=checkbox]:checked:after {
    content: "\2714";
    /* display: unset; */
    font-weight: bold;
}

input[type="checkbox"][disabled] {
    outline: none !important;
    -moz-outline: none;
}