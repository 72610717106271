.table-css-information .thead-dark th:nth-child(3) {
    width: 240px;
}

.table-css-information .thead-dark th:nth-child(4) {
    width: 300px;
}

.table-css-information .thead-dark th:nth-child(5) {
    width: 240px;
}

.background-color-treeParent {
    background-color: rgb(209, 209, 209);
}

/* table-css-information */
.table-css-information input[type="checkbox"] {
    /* font-size: 18px; */
    visibility: hidden;
    font-size: 15px;
    appearance: none;
    /* transform: scale(1.5); */
}

.table-css-information input[type="checkbox"][checked] {
    font-size: 15px;
    visibility: hidden;
    appearance: none;
}

.table-css-information input[type="checkbox"]:checked:after {
    content: "\2714";
    font-weight: bold;
}

.table-css-information input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    margin: 5px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    appearance: none;
}


.table-css-information input[type=checkbox]:checked:after {
    content: "\2714";
    font-weight: bold;
}

/* end-table-css-information */

/* infor-css-check */
.infor-css-check input {
    cursor: pointer;
}

.infor-css-check input[type="checkbox"] {
    font-size: 15px;
    visibility: hidden;
}

.infor-css-check input[type="checkbox"][checked] {
    font-size: 15px;
    visibility: hidden;
    appearance: none;
}

.infor-css-check input[type="checkbox"][checked]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);

}

.infor-css-check input[type=checkbox] {
    content: " ";
    display: inline-block;
    color: #04BF16;
    -moz-border-colors: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
    -moz-appearance: none;
    appearance: none;
}

.infor-css-check input[type=checkbox][disabled] {
    content: " ";
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}


.infor-css-check input[type=checkbox]:after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.infor-css-check input[type=checkbox]:not(:checked):after {
    content: " ";
    background-color: #fff;
    display: inline-block;
    color: #04BF16;
    width: 20px;
    height: 20px;
    line-height: 20px;
    visibility: visible;
    border: 1px solid #04BF16;
    padding: 0 3px;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.infor-css-check input[type=checkbox]:checked:after {
    content: "\2714";
    font-weight: bold;
}

/* end-infor-css-check */



.select-css-infor .Select_selectBody__1w0GW {
    height: 200px !important;
}

.css-border-input {
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.css-search-input {
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    transition: box-shadow .15s ease;
}

.color-text-infor {
    color: #000 !important;
}

/* .w_input {
    width: 30%;
} */