.div-dashboard-box {
    width: 100%;
    height: 120px;
    background-color: #60C3FF;
    padding: 1.3rem;
    align-items: center;
    color: #fff;
}

.border-dashboard-box {
    border-radius: 6px;
    border: 1px solid #60C3FF;
}

.icon-css-box i {
    font-size: 3.5rem;
}

.font-size-h1 {
    font-size: 32px;
    color: #32325d;
}

.icon-user-color {
    color: #fff;
}

.icon-box-open-color {
    color: #fff;
}

.icon-search-color {
    color: #fff;
}

.text-css-box span {
    font-weight: 600;
    color: #fff;
}

.row-align-item {
    align-items: center;
}

.info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.info-box-number {
    display: block;
    margin-top: .25rem;
    font-size: 32px;

}