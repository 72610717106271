:root {
    --color-blue: #02A7F0;
    --color-white: #ffffff;
    --color-black: #000000;
    --color-aqua: #a7a7a7;
    --color-green: #02830F;
    --color-red: red;
    --color-blue-2: #118DEF;
    --font-normal: Arial, Helvetica, sans-serif;
    --color-orange: #F0AA3B;
}