.noActiveSta {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: #000;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.activeSta {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: #04BF16;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.notYesStt {
    display: block;
    width: 5.5rem;
    margin: auto;
    color: orange;
    padding: 3px 0;
    font-weight: 600;
    border-radius: 40px;
}

.noActiveSta {
    background-color: #fff;
    border: 1px solid #000;
}

.notYesStt {
    background-color: #fff;
    border: 1px solid orange;

}

.activeSta {
    background-color: #fff;
    border: 1px solid #04BF16;
}

.box-list {
    border-radius: 6px;
    background: #fff;
    width: 100%;
    border-top: 4px solid #138bef;
}

.header-box-list {
    background: #60C3FF;
}

.header-box-list h4 {
    font-family: 600;
    font-size: 18px;
    padding: 10px 20px;
    margin: 0;
    color: #fff;
}

.body-box-list {
    padding-left: 20px;
    padding-right: 20px;
}

.info-box-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.box-text span {
    width: 100%;
    opacity: 0.7;
}

.mrg-10 {
    margin: 10px 0px;
}

/* .table-box-list tbody {
    opacity: 0.7;
} */

.box-text {
    padding: 5px 0px;
}

.table-box-list thead tr th:nth-child(1) {
    width: 50% !important;
}

.table-box-list-product thead tr th:nth-child(1) {
    width: 15% !important;
}

.table-box-list-product thead tr th {
    background: #fff;
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.table-box-list-product tbody tr td {
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.table-box-list thead tr th {
    background: #fff;
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.table-box-list .table-tr-box-list tr {
    border-bottom: 1px solid #000;
}

.table-tr-box-list th {
    opacity: 1;
    background: #fff;
}

/* .table-box-list .tbody tr {} */

.table-box-list tbody tr td {
    padding: 8px 10px;
    /* text-align: left; */
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #32325d !important;
}

.text-td {
    /* display: block; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    width: 297px;
    font-size: 13px;
    /* text-align: center; */
}

.text-center-status {
    text-align: center;

    font-size: 11px;
}

.mr-bottom {
    border-bottom: 1px solid #d1d1d1;
}

.footer-box-list {
    padding: 20px;
    text-align: right;
}

.footer-box-list a {
    font-weight: 600;
}

.header-box-list input {
    width: 60px;
    margin-right: 20px;

}

.display-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mr-b-5 {
    margin-bottom: 5px;
}

.mr-t-box {
    margin-top: 30px;
}

.table-tr-box-list-product th {
    text-align: center;
}

.text-algin-img {
    text-align: center;
}

.text-algin-text {
    text-align: left;
}

.text-algin-number {
    text-align: right;
}

.text-algin-date {
    text-align: center;
}

.table-box-list tbody tr td span {
    /* -webkit-filter: blur(3%);
    filter: blur(3%);
    opacity: 0.7; */
    color: #525f7f;
}