.wrap-insert-or-update-zone-item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
}

.wrap-insert-or-update-zone-item-check {
    display: flex;
    height: 43px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0px !important;
    padding: 10px !important;
    cursor: pointer;
}

.wrap-insert-or-update-zone-item-label {
    min-width: 150px;
    margin: 0px !important;
    padding: 0px !important;
    font-size: 14px;
    font-family: var(--font-normal);
    font-weight: 600;
    color: var(--color-black);
}

.wrap-insert-or-update-zone-item-check-item {
    margin-right: 5px;
    cursor: pointer;
}

.wrap-insert-or-update-zone-item-check-item-radio {
    cursor: pointer;
}

.wrap-insert-or-update-zone-item-check-item-label {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 14px;
    font-family: var(--font-normal);
    color: var(--color-black);
    margin-left: 5px !important;
    cursor: pointer;
}

.wrap-insert-or-update-zone-item-label {
    display: flex;
    height: 43px;
    font-size: 14px;
    font-family: var(--font-normal);
    color: var(--color-black);
    align-items: center;
}

.wrap-insert-or-update-zone-item-input {
    flex: 1;
    font-size: 14px;
    font-family: var(--font-normal);
    width: 100%;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    -webkit-border-radius: 0.375rem;
    -moz-border-radius: 0.375rem;
    -ms-border-radius: 0.375rem;
    -o-border-radius: 0.375rem;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.78rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
}

.wrap-insert-or-update-zone-add {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}

.wrap-insert-or-update-zone-add-button {
    background-color: #11C7EF;
    border: none;
    font-size: 14px;
    font-family: var(--font-normal);
    color: var(--color-white);
    padding: 5px;
    border-radius: 5px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrap-insert-or-update-zone-add-button-icon {
    margin-right: 5px;
}

.wrap-insert-or-update-zone-area-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.wrap-insert-or-update-zone-area-list-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 45%;
    margin-top: 5px;
    background-color: #11C7EF;
}

.wrap-insert-or-update-zone-area-list-item-label {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-family: var(--font-normal);
    color: var(--color-white);
    flex: 1;
    /* background-color: var(--color-aqua); */
    padding: 1px 5px;
    border-radius: 5px;
}

.wrap-insert-or-update-zone-area-list-item-delete {
    border: none;
    background: none;
    outline: none;
    margin: 0px;
    padding: 0px;
    margin-left: 5px;
}

.wrap-insert-or-update-zone-area-list-item-delete-icon {
    /* width: 24px;
    height: 24px; */
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-top: -20px;
}

.wrap-insert-or-update-zone-item-box {
    flex: 1
}

.wrap-insert-or-update-zone-area-list {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
}

.wrap-insert-or-update-zone-item-box-2 {
    margin-left: 5px;
    width: 150px;
}

.wrap-insert-or-update-zone-item-location {
    outline: none;
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.wrap-insert-or-update-zone-item-location-icon {
    width: 16px;
}

.wrap-manage-company-location-current {
    width: 48px;
    height: 48px;
    position: absolute;
    right: 6px;
    top: 60px;
    border: none;
    background: none;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrap-manage-company-location-current-icon {
    width: 32px;
    height: 32px;
}

.wrap-manage-company-location-search {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 5px;
    width: calc(100% - 60px);
}

.wrap-manage-company-location-search-input {
    width: 100%;
    border-radius: 5px;
    border: none;
    padding: 5px;
    color: black;
    outline: none;
    font-size: 14px;
}