.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-right: 4px solid #000 !important;
    border-bottom: 4px solid #000 !important;
    pointer-events: none;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 5px) !important;
    right: 19px;
    width: 8px !important;
    height: 8px !important;
}

.table-css-products .thead-dark th:nth-child(2) {
    width: 250px;
}

.table-css-products .thead-dark th:nth-child(3) {
    width: 500px;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.img-products img {
    width: 82px !important;
    height: 82px !important;
}

.select-search__input {
    height: calc(1em + 1rem + 2px);
    transition: box-shadow .15s ease;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    color: #525f7f;
    border-radius: 0.375rem;
}

.css-button-no-yes-product {
    background-color: #d5d7dd !important;
    border-color: #d5d7dd;
    color: #000;
}

.css-position-tab {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: #fff;
    z-index: 100;
    padding: 1.5rem;
    margin: 0 !important;
    border-bottom: 1px solid #000;
}

.modal-body-no-padding {
    padding: 1.5rem !important;

}

/* .style-margin-css {
    margin: 1.5rem !important;
} */

.Products_formControl__1BDp3 {
    align-items: inherit !important;
}

.css-popup-search {
    width: 100%;
    height: 100px;

    position: absolute;
    z-index: 10;
    right: 0;
    left: 0;


}

.css-div-search {
    width: 87%;
    background: #fff;
    height: 100%;
    margin: 0 auto;
    border-radius: 0.375rem;
    padding: 5px 10px;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
}

.css-a-search a {
    text-decoration: none;
    color: #000;
}


.css-padding-input-product {
    height: calc(1em + 1rem + 2px) !important;
    padding: 0.3rem 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    color: #525f7f;
}

.Select_select__1GZAZ {
    height: calc(1em + 1rem + 2px) !important;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    color: #525f7f;
}

.justify-tabs {
    justify-content: center;
}

.product-view-popup-modal-body {
    padding: 0;
}

/* .margin-tabs-detail {
    margin: 1.5rem;
} */
.css-hr {
    width: 90%;
    border-bottom: 1px solid #000;
    margin: 0 auto;
    padding: 0;
    position: absolute;
    top: 0;
}

.tabs-item-products {
    background: #1ec6e8;
    font-weight: 600;
    border-radius: 0;
    color: #fff;
    border: 1px solid #1ec6e8;

}

.width-label-view-products {
    height: 0px !important;
    align-items: normal !important;
}

.w_div_100 {
    width: 100%;
    /* align-items: flex-end; */
}