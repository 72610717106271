.product-report-filter-wrap {
    display: flex;
    align-items: center;
}

.product-report-filter {
    display: flex;
    align-items: center;
}

.product-report-filter-item {
    display: flex;
    align-items: center;
    margin: 0px 5px;
}

.product-report-filter-item-label {
    margin-left: 5px; 
    margin-bottom: 0px;
}

.product-report-title {
    font-size: 14px;
    color: var(--color-blue-2);
    text-align: center;
    margin-bottom: 0px;
}

.product-report-address {
    font-size: 14px;
    color: var(--color-blue-2);
    text-align: center;
}

.product-report-time {
    font-size: 14px;
    text-align: center;
}

.product-report-field {
    font-size: 14px;
    text-align: center;
}

.product-report-filter-result-month, .product-report-filter-result-precious, .product-report-filter-result-year {
    display: flex;
    align-items: center;
}

.product-report-filter-result-item {
    display: flex;
    align-items: center;
    margin: 0px 5px;
}

.product-report-filter-result-item-label {
    margin-bottom: 0px;
}

.product-report-filter-result-item-input {
    margin-left: 5px;
    width: 60px;
    text-align: center;
}

.product-report-filter-submit {
    margin-bottom: 10px !important;
}

.horizontal-bar-chart {
    display: flex;
    width: 100%;
    margin-top: 30px;
    position: relative;
}

.horizontal-bar-chart-header {
    width: 100px;
    position: relative;
    border-right: 1px solid black;
}

.horizontal-bar-chart-header-item {
    width: 100%;
    left: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.horizontal-bar-chart-header-item-title {
    font-size: 12px;
    color: black;
    font-weight: 600;
    line-height: 1rem;
    margin: 0px;
    padding: 0px;
}

.horizontal-bar-chart-body {
    position: relative;
    flex: 1;
    border-bottom: 1px solid black;
    display: flex;
}

.horizontal-bar-chart-body-item {
    position: absolute;
    width: 0%;
    left: 0px;
    background-color: rgb(96, 202, 255);
    cursor: pointer;
    z-index: 1;
    transition: all 0.5s ease-out;
}

.horizontal-bar-chart-body-item-content {
    width: 100%;
    height: 100%;
    position: relative;
}

.horizontal-bar-chart-body-column {
    width: 50px;
    height: 100%;
    position: relative;
}

.horizontal-bar-chart-body-column-text {
    font-size: 12px;
    color: black;
    font-weight: 600;
    position: absolute;
    left: -4.5px;
    bottom: -20px;
    line-height: 1rem;
    padding: 0px;
    margin: 0px;
}

.horizontal-bar-chart-body-item-content-text {
    font-size: 16px;
    color: black;
    font-weight: 600;
    position: absolute;
    left: calc(100% + 5px);
    top: 50%;
    transform: translateY(-50%);
    margin: 0px;
    padding: 0px;
}

.product-report-function {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-report-function-back, .product-report-function-view {
    flex: 1;
}

.product-report-function-view {
    justify-content: flex-end;
    display: flex;
}

.product-report-back-button, .product-report-view-button {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.horizontal-bar-chart-unit {
    font-size: 12px;
    font-weight: 100;
    font-style: italic;
    color: black;
    position: absolute;
    top: 0px;
    z-index: 1;
    left: 145px;
    transform: translateX(-50%);
    top: -25px;
    margin: 0px;
    padding: 0px;
    line-height: 1rem;
}

.product-report-list-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-report-list-empty-text {
    line-height: 1rem;
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    text-align: center;
}