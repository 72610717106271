.modal-body-item {
    display: flex;
    align-items: center;
}

.modal-body-item-2 {
    display: flex;
    flex-direction: column;
}

.modal-body-item-label {
    min-width: 200px;
    font-size: 1rem;
    color: #000000;
    margin: 0px;
}

.modal-body-item-value {
    font-size: 1rem;
    color: #000000;
    font-weight: 600;
    margin: 0px;
}

.modal-footer .modal-footer-unconfirm {
    background-color: aqua;
    margin-right: 13px !important;
    color: #000000;
}

.modal-body-item-textarea {
    margin-top: 5px;
}

.modal-body-item-file-item {
    /* width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row; */
    margin-top: 5px;
    display: flex;
}

/* .modal-body-item-file-item-image {
    min-width: 100px;
    min-height: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 5px;
} */

.modal-body-item-file-item-image {
    width: 100%;
    height: 100%;
    max-height: 250px;
    max-width: 250px;
    object-fit: cover;
}

.modal-body-item-file-item-text {
    font-size: 0.8rem;
    color: #000000;
    margin: 0px;
    margin-left: 5px;
    color: #09b2fd;
}

.modal-body-item-image {
    margin: 5px 0px;
    width: auto;
    height:auto;
    max-width: 500px;
    max-height:250px;
    object-fit: cover;
}

.modal-body-item-file-item-icon {
    width: 24px;
    height: 24px;
    margin-top: 5px;
}