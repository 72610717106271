.select {
    cursor:'pointer';
    display: block;
    position: relative;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    -webkit-border-radius: 0.375rem;
    -moz-border-radius: 0.375rem;
    -ms-border-radius: 0.375rem;
    -o-border-radius: 0.375rem;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.selectDisable {
    display: block;
    position: relative;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-color: #dddddd;
    background-clip: padding-box;
    border: 1px solid #cad1d7;
    border-radius: 0.375rem;
    -webkit-border-radius: 0.375rem;
    -moz-border-radius: 0.375rem;
    -ms-border-radius: 0.375rem;
    -o-border-radius: 0.375rem;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -webkit-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -moz-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -ms-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    -o-transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.overSelectHeight {
    padding: 4px 0.75rem;
}

.selectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.selectHeader .text {
    overflow: hidden;
    white-space: nowrap;    
    text-overflow: ellipsis;
}

.overSelectHeight .selectHeader {
    height: 32px;
}

.selectBody {
    position: absolute;
    width: 100%;
    max-height: 300px;
    top: 100%;
    left: 0;
    padding: 10px;
    background: white;
    overflow: hidden;
    overflow-y: auto;   
    box-shadow: 1px 1px 8px 1px grey;
    -webkit-box-shadow: 1px 1px 8px 1px grey;
    -moz-box-shadow: 1px 1px 8px 1px grey;
    -ms-box-shadow: 1px 1px 8px 1px grey;
    -o-box-shadow: 1px 1px 8px 1px grey;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transform: translateY(4px);
    -moz-transform: translateY(4px);
    -webkit-transform: translateY(4px);
    -ms-transform: translateY(4px);
    -o-transform: translateY(4px);
    cursor: pointer;
    z-index: 2;
}

.selectBody :hover {
    background-color:#1e90ff;
}

.selectBody .items {
    display: flex;
    padding: 5px;
    justify-content: flex-start;
    align-items: center;
}

/* .selectBody .items:first-child {
    border-bottom: 1px solid #f1f1f1;
} */

.selectBody .items:last-child {
    border-bottom: none;
}

.selectBody .text {
    width: 100%;
    padding-left: 5px;
    overflow: hidden;
    white-space: nowrap;    
    text-overflow: ellipsis;
    pointer-events: none;
}

.overHeight {
    display: flex;
    width: 100%;
    height: 32px;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden!important;
    overflow-y: visible!important;
}

.tips {
    height: 25px;
    padding: 2px 10px;
    margin-right: 5px;
    border: 1px solid white;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
}
.hoverItem :hover{
    background:aqua !important;
}

.selectPopupClose:hover {
    background: none !important;
}

.selectPopupCloseButton:hover {
    background: none !important;
}

.selectPopupCloseButtonIcon:hover {
    background: none !important;
}