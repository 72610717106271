.css-border-webConfig {
    border-radius: 0.375rem;
}

.config-system-tab-item-button {
    border-radius: 0;
    border-bottom: 2px solid #fff;
    /* border-left: #fff; */
    border-top: #fff;
    /* border-right: #fff; */
    margin-right: 0px;
    background: #1ec6e8;
    color: #fff;
    font-weight: 600;
}

.config-system-tab .active {
    position: relative;
    bottom: -1px;
    background: #fff;
    color: #1ec6e8;
    border-top: 1px solid #1ec6e8;
    border-left: 1px solid #1ec6e8;
    border-right: 1px solid #1ec6e8;
}


.config-system-content-info-company {
    padding: 15px;
    background: #fff;
    border: 1px solid #1ec6e8;

}

.config-system-content-config-system {
    padding: 15px;
    background: #fff;
    border: 1px solid #1ec6e8;
}

.config-system-content-config-server {
    padding: 15px;
    background: #fff;
    margin-top: 0 !important;
    border: 1px solid #1ec6e8;
}

.config-system-content-config-stamp-price {
    padding: 15px;
    background: #fff;
    margin-top: 0 !important;
    border: 1px solid #1ec6e8;
}

.margin-tabs-input {
    margin: 0 !important;
    border-bottom: 0px !important;
}

.config-system-tab-item {
    border-color: #fff;
    border-bottom-width: 0px;
}