.table-css-typeZoneProperty .thead-dark th:nth-child(2) {
    width: 300px;
}

.table-css-typeZoneProperty .thead-dark th:nth-child(3) {
    width: 500px;
}

/* .SelectParent_select__17uvC {
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    transition: box-shadow .15s ease;
    color: inherit;

} */

.css-width-label {
    width: 120px !important;
    font-weight: 600;
}

.css-icon-type-zone-property {
    width: 82px;
    height: 82px;
}

.css-icon-button-type-Zone-property {
    color: #fff;
    /* background: rgba(0, 0, 0, 0.5); */
    background-color: #d9001b;
    height: 20px;
    padding: 0;
    margin: auto;
    width: 20px;
    margin: auto;
    line-height: 20px;
    border-radius: 0;
    /* border-color: rgba(0, 0, 0, 0.5); */
    border-color: #d9001b;
    border-radius: 50%;
    font-weight: bold;
}

.css-icon-button-type-Zone-property:hover {
    background: #d9001b;
    border-color: #d9001b;
}

.css-position-x {
    position: relative;

}

.SelectParent_select__3314y {
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    border: 0;
    transition: box-shadow .15s ease;
}

.SelectParent_selectHeader__2ayWD {
    height: 20px;
}