.pageBoxArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.pageBoxArea p {
    margin: 0;
}

.pageArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style-type: none;
}

.pageItem {
    color: #5fc2ff;
    width: 35px;
    height: 35px;
    display: inherit;
    background: white;
    text-align: center;
    margin-right: 10px;
    align-items: inherit;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgb(128 128 128 / 36%);
    -webkit-box-shadow: 0px 0px 3px 1px rgb(128 128 128 / 36%);
    -moz-box-shadow: 0px 0px 3px 1px rgb(128 128 128 / 36%);
    -ms-box-shadow: 0px 0px 3px 1px rgb(128 128 128 / 36%);
    -o-box-shadow: 0px 0px 3px 1px rgb(128 128 128 / 36%);
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
}

.pageItem:last-child {
    margin-right: 0;
}


.pageItem a {
    display: inherit;
    width: inherit;
    height: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.pageActiveItem {
    color: white;
    background: #5fc2ff;
}

.breakPageItem {
    width: 13px;
    margin-right: 10px;
}