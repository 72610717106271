.footerArea {
    position: fixed;
    width: 100%;
    bottom: 0;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
    height: 0;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }