.modalButtonArea {
    justify-content: center;
    flex-direction: row;
    box-shadow: 0px 0px 7px 0px #80808094;
    -webkit-box-shadow: 0px 0px 7px 0px #80808094;
    -moz-box-shadow: 0px 0px 7px 0px #80808094;
    -ms-box-shadow: 0px 0px 7px 0px #80808094;
    -o-box-shadow: 0px 0px 7px 0px #80808094;
}

.modalButtonArea button {
    display: flex;
    /* width: 110px; */
    height: 40px;
    margin: auto;
    margin-left: 0!important;
    margin-right: 0!important;
    text-transform: uppercase;
    align-items: center;
}

.modalButtonArea button:first-child {
    margin-right: 13px!important;
}

/* .modalButtonArea button span {
    margin-left: 5px;
} */

.moduleHeaderArea {
    background: #09b2fd;
    justify-content: center;
}

.moduleHeaderArea h5 {
    font-size: 1rem;
    color: white;
    text-transform: uppercase;
}

.disbaleBtn {
    background: #f1f1f1;
}

.disbaleBtn span {
    color: white;
}
.iconButtonLuu:hover {
    content: url("../../assets/img/buttons/btnLuu1LocBui.png"); 
    display: block;
    cursor: pointer;
}
.iconButtonDong:hover {
    content: url("../../assets/img/buttons/btnDong1LocBui.png"); 
    display: block;
    cursor: pointer;
} 