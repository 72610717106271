.table-css-partnerVerify .thead-dark th:nth-child(2) {
    width: 500px;
}

.table-css-partnerVerify .thead-dark th:nth-child(3) {
    width: 500px;
}

.table-css-partnerVerify .thead-dark th:nth-child(6) {
    width: 590px;
}

.table-hover-css:hover {
    background-color: rgba(0, 0, 0, .075);
}

.partnerVerify-img img {
    width: 82px !important;
    height: 82px !important;
}

.select-search__input {
    padding: 0.3rem 0.75rem !important;
}