//
// Header
//

.header {
    position: relative;
}


.table td, .table th {
    padding: .42rem;
}