.wrap-insert-or-update-zone-item-input {
    height: calc(1em + 1rem + 2px) !important;
}

.wrap-css-planting-zone {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.wrap-width-plating-zone {
    width: 100%;
}

.margin-left-button-planting-zone {
    margin-left: -15px !important;
}

.position-button-plantinf-zone {
    position: absolute;
    top: 750px;
}

.css-icon-button-type-Zone-planting {
    color: #fff;
    /* background: rgba(0, 0, 0, 0.5); */
    background-color: #d9001b;
    height: 20px;
    padding: 0;
    margin: auto;
    width: 20px;
    margin: auto;
    line-height: 20px;
    border-radius: 0;
    /* border-color: rgba(0, 0, 0, 0.5); */
    border-color: #d9001b;
    border-radius: 50%;
    font-weight: bold;
}

.css-icon-button-type-Zone-planting:hover {
    background: #d9001b;
    border-color: #d9001b;
}

.css-position-x {
    position: relative;

}

.table-css-planting-zone .thead-dark th:nth-child(2) {
    width: 250px;
}

.Select_selectDisable__2zhzV {
    height: calc(1em + 1rem + 2px);
    padding: 0.3rem 0.75rem;
    box-shadow: 0 1px 3px rgb(50 50 93 / 73%), 0 1px 0 rgb(0 0 0 / 2%);
    transition: box-shadow .15s ease;
    color: inherit;
}